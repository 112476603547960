"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignalConnectionImpl = void 0;
/**
 * Implementation of SignalConnection, for internal use only.
 * @private
 */
class SignalConnectionImpl {
    /**
     * @param link The actual link of the connection.
     * @param parentCleanup Callback to cleanup the parent signal when a connection is disconnected
     */
    constructor(link, parentCleanup) {
        this.link = link;
        this.parentCleanup = parentCleanup;
    }
    disconnect() {
        if (this.link !== null) {
            this.link.unlink();
            this.link = null;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.parentCleanup();
            this.parentCleanup = null;
            return true;
        }
        return false;
    }
    set enabled(enable) {
        if (this.link)
            this.link.setEnabled(enable);
    }
    get enabled() {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        return this.link !== null && this.link.isEnabled();
    }
}
exports.SignalConnectionImpl = SignalConnectionImpl;
