import { __assign, __awaiter, __generator, __read } from "tslib";
import { CampaignParser } from '@amplitude/analytics-client-common';
import { createCampaignEvent, getDefaultExcludedReferrers, getStorageKey, isNewCampaign } from './helpers';
import { isNewSession } from '@amplitude/analytics-client-common';
export var webAttributionPlugin = function (options) {
    var _this = this;
    if (options === void 0) { options = {}; }
    var plugin = {
        name: '@amplitude/plugin-web-attribution-browser',
        type: 'before',
        setup: function (config, amplitude) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var pluginConfig, storage, storageKey, _b, currentCampaign, previousCampaign, isEventInNewSession, campaignEvent;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            pluginConfig = __assign({ initialEmptyValue: 'EMPTY', resetSessionOnNewCampaign: false, excludeReferrers: getDefaultExcludedReferrers((_a = config.cookieOptions) === null || _a === void 0 ? void 0 : _a.domain) }, options);
                            config.loggerProvider.log('Installing @amplitude/plugin-web-attribution-browser.');
                            storage = config.cookieStorage;
                            storageKey = getStorageKey(config.apiKey, 'MKTG');
                            return [4 /*yield*/, Promise.all([
                                    new CampaignParser().parse(),
                                    storage.get(storageKey),
                                ])];
                        case 1:
                            _b = __read.apply(void 0, [_c.sent(), 2]), currentCampaign = _b[0], previousCampaign = _b[1];
                            isEventInNewSession = isNewSession(config.sessionTimeout, config.lastEventTime);
                            if (isNewCampaign(currentCampaign, previousCampaign, pluginConfig, isEventInNewSession)) {
                                if (pluginConfig.resetSessionOnNewCampaign) {
                                    amplitude.setSessionId(Date.now());
                                    config.loggerProvider.log('Created a new session for new campaign.');
                                }
                                config.loggerProvider.log('Tracking attribution.');
                                campaignEvent = createCampaignEvent(currentCampaign, pluginConfig);
                                amplitude.track(campaignEvent);
                                void storage.set(storageKey, currentCampaign);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        execute: function (event) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, event];
        }); }); },
    };
    return plugin;
};
