"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorLast = void 0;
const Collector_1 = require("./Collector");
/**
 * Returns the result of the last signal handler from a signal emission.
 *
 * @typeparam THandler The function signature to be implemented by handlers.
 */
class CollectorLast extends Collector_1.Collector {
    handleResult(result) {
        this.result = result;
        return true;
    }
    /**
     * Get the result of the last signal handler.
     */
    getResult() {
        return this.result;
    }
    /**
     * Reset the result
     */
    reset() {
        delete this.result;
    }
}
exports.CollectorLast = CollectorLast;
