"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Signal = void 0;
const SignalConnection_1 = require("./SignalConnection");
const SignalLink_1 = require("./SignalLink");
/**
 * A signal is a way to publish and subscribe to events.
 *
 * @typeparam THandler The function signature to be implemented by handlers.
 */
class Signal {
    constructor() {
        this.head = new SignalLink_1.SignalLink();
        this.hasNewLinks = false;
        this.emitDepth = 0;
        this.connectionsCount = 0;
    }
    /**
     * @returns The number of connections on this signal.
     */
    getConnectionsCount() {
        return this.connectionsCount;
    }
    /**
     * @returns true if this signal has connections.
     */
    hasConnections() {
        return this.connectionsCount > 0;
    }
    /**
     * Subscribe to this signal.
     *
     * @param callback This callback will be run when emit() is called.
     * @param order Handlers with a higher order value will be called later.
     */
    connect(callback, order = 0) {
        this.connectionsCount++;
        const link = this.head.insert(callback, order);
        if (this.emitDepth > 0) {
            this.hasNewLinks = true;
            link.newLink = true;
        }
        return new SignalConnection_1.SignalConnectionImpl(link, () => this.decrementConnectionCount());
    }
    decrementConnectionCount() {
        this.connectionsCount--;
    }
    /**
     * Unsubscribe from this signal with the original callback instance.
     * While you can use this method, the SignalConnection returned by connect() will not be updated!
     *
     * @param callback The callback you passed to connect().
     */
    disconnect(callback) {
        for (let link = this.head.next; link !== this.head; link = link.next) {
            if (link.callback === callback) {
                this.decrementConnectionCount();
                link.unlink();
                return true;
            }
        }
        return false;
    }
    /**
     * Disconnect all handlers from this signal event.
     */
    disconnectAll() {
        while (this.head.next !== this.head) {
            this.head.next.unlink();
        }
        this.connectionsCount = 0;
    }
    /**
     * Publish this signal event (call all handlers).
     */
    emit(...args) {
        this.emitDepth++;
        for (let link = this.head.next; link !== this.head; link = link.next) {
            if (link.isEnabled() && link.callback)
                link.callback.apply(null, args);
        }
        this.emitDepth--;
        this.unsetNewLink();
    }
    emitCollecting(collector, args) {
        this.emitDepth++;
        for (let link = this.head.next; link !== this.head; link = link.next) {
            if (link.isEnabled() && link.callback) {
                const result = link.callback.apply(null, args);
                if (!collector.handleResult(result))
                    break;
            }
        }
        this.emitDepth--;
        this.unsetNewLink();
    }
    unsetNewLink() {
        if (this.hasNewLinks && this.emitDepth === 0) {
            for (let link = this.head.next; link !== this.head; link = link.next)
                link.newLink = false;
            this.hasNewLinks = false;
        }
    }
}
exports.Signal = Signal;
