"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorArray = void 0;
const Collector_1 = require("./Collector");
/**
 * Returns the result of the all signal handlers from a signal emission in an array.
 *
 * @typeparam THandler The function signature to be implemented by handlers.
 */
class CollectorArray extends Collector_1.Collector {
    constructor() {
        super(...arguments);
        this.result = [];
    }
    handleResult(result) {
        this.result.push(result);
        return true;
    }
    /**
     * Get the list of results from the signal handlers.
     */
    getResult() {
        return this.result;
    }
    /**
     * Reset the result
     */
    reset() {
        this.result.length = 0;
    }
}
exports.CollectorArray = CollectorArray;
