"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignalLink = void 0;
/**
 * SignalLink implements a doubly-linked ring with nodes containing the signal handlers.
 * @private
 */
class SignalLink {
    constructor(prev = null, next = null, order = 0) {
        this.enabled = true;
        this.newLink = false;
        this.callback = null;
        this.prev = prev !== null && prev !== void 0 ? prev : this;
        this.next = next !== null && next !== void 0 ? next : this;
        this.order = order;
    }
    isEnabled() {
        return this.enabled && !this.newLink;
    }
    setEnabled(flag) {
        this.enabled = flag;
    }
    unlink() {
        this.callback = null;
        this.next.prev = this.prev;
        this.prev.next = this.next;
    }
    insert(callback, order) {
        let after = this.prev;
        while (after !== this) {
            if (after.order <= order)
                break;
            after = after.prev;
        }
        const link = new SignalLink(after, after.next, order);
        link.callback = callback;
        after.next = link;
        link.next.prev = link;
        return link;
    }
}
exports.SignalLink = SignalLink;
