"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collector = void 0;
/**
 * Base class for collectors.
 *
 * @typeparam THandler The function signature to be implemented by handlers.
 */
class Collector {
    /**
     * Create a new collector.
     *
     * @param signal The signal to emit.
     */
    constructor(signal) {
        // eslint-disable-next-line dot-notation
        this.emit = (...args) => {
            // eslint-disable-next-line dot-notation
            signal["emitCollecting"](this, args);
        };
    }
}
exports.Collector = Collector;
