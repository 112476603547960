import { LogLevel, } from '@amplitude/analytics-types';
import { AMPLITUDE_SERVER_URL, AMPLITUDE_BATCH_SERVER_URL, EU_AMPLITUDE_SERVER_URL, EU_AMPLITUDE_BATCH_SERVER_URL, } from './constants';
import { Logger } from './logger';
export var getDefaultConfig = function () { return ({
    flushMaxRetries: 12,
    flushQueueSize: 200,
    flushIntervalMillis: 10000,
    instanceName: '$default_instance',
    logLevel: LogLevel.Warn,
    loggerProvider: new Logger(),
    offline: false,
    optOut: false,
    serverUrl: AMPLITUDE_SERVER_URL,
    serverZone: 'US',
    useBatch: false,
}); };
var Config = /** @class */ (function () {
    function Config(options) {
        var _a, _b, _c, _d;
        this._optOut = false;
        var defaultConfig = getDefaultConfig();
        this.apiKey = options.apiKey;
        this.flushIntervalMillis = (_a = options.flushIntervalMillis) !== null && _a !== void 0 ? _a : defaultConfig.flushIntervalMillis;
        this.flushMaxRetries = options.flushMaxRetries || defaultConfig.flushMaxRetries;
        this.flushQueueSize = options.flushQueueSize || defaultConfig.flushQueueSize;
        this.instanceName = options.instanceName || defaultConfig.instanceName;
        this.loggerProvider = options.loggerProvider || defaultConfig.loggerProvider;
        this.logLevel = (_b = options.logLevel) !== null && _b !== void 0 ? _b : defaultConfig.logLevel;
        this.minIdLength = options.minIdLength;
        this.plan = options.plan;
        this.ingestionMetadata = options.ingestionMetadata;
        this.offline = options.offline !== undefined ? options.offline : defaultConfig.offline;
        this.optOut = (_c = options.optOut) !== null && _c !== void 0 ? _c : defaultConfig.optOut;
        this.serverUrl = options.serverUrl;
        this.serverZone = options.serverZone || defaultConfig.serverZone;
        this.storageProvider = options.storageProvider;
        this.transportProvider = options.transportProvider;
        this.useBatch = (_d = options.useBatch) !== null && _d !== void 0 ? _d : defaultConfig.useBatch;
        this.loggerProvider.enable(this.logLevel);
        var serverConfig = createServerConfig(options.serverUrl, options.serverZone, options.useBatch);
        this.serverZone = serverConfig.serverZone;
        this.serverUrl = serverConfig.serverUrl;
    }
    Object.defineProperty(Config.prototype, "optOut", {
        get: function () {
            return this._optOut;
        },
        set: function (optOut) {
            this._optOut = optOut;
        },
        enumerable: false,
        configurable: true
    });
    return Config;
}());
export { Config };
export var getServerUrl = function (serverZone, useBatch) {
    if (serverZone === 'EU') {
        return useBatch ? EU_AMPLITUDE_BATCH_SERVER_URL : EU_AMPLITUDE_SERVER_URL;
    }
    return useBatch ? AMPLITUDE_BATCH_SERVER_URL : AMPLITUDE_SERVER_URL;
};
export var createServerConfig = function (serverUrl, serverZone, useBatch) {
    if (serverUrl === void 0) { serverUrl = ''; }
    if (serverZone === void 0) { serverZone = getDefaultConfig().serverZone; }
    if (useBatch === void 0) { useBatch = getDefaultConfig().useBatch; }
    if (serverUrl) {
        return { serverUrl: serverUrl, serverZone: undefined };
    }
    var _serverZone = ['US', 'EU'].includes(serverZone) ? serverZone : getDefaultConfig().serverZone;
    return {
        serverZone: _serverZone,
        serverUrl: getServerUrl(_serverZone, useBatch),
    };
};
