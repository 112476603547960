import { __assign, __awaiter, __extends, __generator } from "tslib";
import { LogLevel, } from '@amplitude/analytics-types';
import { Config, Logger, MemoryStorage, UUID } from '@amplitude/analytics-core';
import { CookieStorage, getCookieName, FetchTransport, getQueryParams } from '@amplitude/analytics-client-common';
import { LocalStorage } from './storage/local-storage';
import { SessionStorage } from './storage/session-storage';
import { XHRTransport } from './transports/xhr';
import { SendBeaconTransport } from './transports/send-beacon';
import { parseLegacyCookies } from './cookie-migration';
import { DEFAULT_IDENTITY_STORAGE, DEFAULT_SERVER_ZONE } from './constants';
// Exported for testing purposes only. Do not expose to public interface.
var BrowserConfig = /** @class */ (function (_super) {
    __extends(BrowserConfig, _super);
    function BrowserConfig(apiKey, appVersion, cookieStorage, cookieOptions, defaultTracking, deviceId, flushIntervalMillis, flushMaxRetries, flushQueueSize, identityStorage, ingestionMetadata, instanceName, lastEventId, lastEventTime, loggerProvider, logLevel, minIdLength, offline, optOut, partnerId, plan, serverUrl, serverZone, sessionId, sessionTimeout, storageProvider, trackingOptions, transport, useBatch, userId, pageCounter) {
        if (cookieStorage === void 0) { cookieStorage = new MemoryStorage(); }
        if (cookieOptions === void 0) { cookieOptions = {
            domain: '',
            expiration: 365,
            sameSite: 'Lax',
            secure: false,
            upgrade: true,
        }; }
        if (flushIntervalMillis === void 0) { flushIntervalMillis = 1000; }
        if (flushMaxRetries === void 0) { flushMaxRetries = 5; }
        if (flushQueueSize === void 0) { flushQueueSize = 30; }
        if (identityStorage === void 0) { identityStorage = DEFAULT_IDENTITY_STORAGE; }
        if (loggerProvider === void 0) { loggerProvider = new Logger(); }
        if (logLevel === void 0) { logLevel = LogLevel.Warn; }
        if (offline === void 0) { offline = false; }
        if (optOut === void 0) { optOut = false; }
        if (serverUrl === void 0) { serverUrl = ''; }
        if (serverZone === void 0) { serverZone = DEFAULT_SERVER_ZONE; }
        if (sessionTimeout === void 0) { sessionTimeout = 30 * 60 * 1000; }
        if (storageProvider === void 0) { storageProvider = new LocalStorage({ loggerProvider: loggerProvider }); }
        if (trackingOptions === void 0) { trackingOptions = {
            ipAddress: true,
            language: true,
            platform: true,
        }; }
        if (transport === void 0) { transport = 'fetch'; }
        if (useBatch === void 0) { useBatch = false; }
        var _this = _super.call(this, { apiKey: apiKey, storageProvider: storageProvider, transportProvider: createTransport(transport) }) || this;
        _this.apiKey = apiKey;
        _this.appVersion = appVersion;
        _this.cookieOptions = cookieOptions;
        _this.defaultTracking = defaultTracking;
        _this.flushIntervalMillis = flushIntervalMillis;
        _this.flushMaxRetries = flushMaxRetries;
        _this.flushQueueSize = flushQueueSize;
        _this.identityStorage = identityStorage;
        _this.ingestionMetadata = ingestionMetadata;
        _this.instanceName = instanceName;
        _this.loggerProvider = loggerProvider;
        _this.logLevel = logLevel;
        _this.minIdLength = minIdLength;
        _this.offline = offline;
        _this.partnerId = partnerId;
        _this.plan = plan;
        _this.serverUrl = serverUrl;
        _this.serverZone = serverZone;
        _this.sessionTimeout = sessionTimeout;
        _this.storageProvider = storageProvider;
        _this.trackingOptions = trackingOptions;
        _this.transport = transport;
        _this.useBatch = useBatch;
        _this._optOut = false;
        _this._cookieStorage = cookieStorage;
        _this.deviceId = deviceId;
        _this.lastEventId = lastEventId;
        _this.lastEventTime = lastEventTime;
        _this.optOut = optOut;
        _this.sessionId = sessionId;
        _this.pageCounter = pageCounter;
        _this.userId = userId;
        _this.loggerProvider.enable(_this.logLevel);
        return _this;
    }
    Object.defineProperty(BrowserConfig.prototype, "cookieStorage", {
        get: function () {
            return this._cookieStorage;
        },
        set: function (cookieStorage) {
            if (this._cookieStorage !== cookieStorage) {
                this._cookieStorage = cookieStorage;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "deviceId", {
        get: function () {
            return this._deviceId;
        },
        set: function (deviceId) {
            if (this._deviceId !== deviceId) {
                this._deviceId = deviceId;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "userId", {
        get: function () {
            return this._userId;
        },
        set: function (userId) {
            if (this._userId !== userId) {
                this._userId = userId;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "sessionId", {
        get: function () {
            return this._sessionId;
        },
        set: function (sessionId) {
            if (this._sessionId !== sessionId) {
                this._sessionId = sessionId;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "optOut", {
        get: function () {
            return this._optOut;
        },
        set: function (optOut) {
            if (this._optOut !== optOut) {
                this._optOut = optOut;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "lastEventTime", {
        get: function () {
            return this._lastEventTime;
        },
        set: function (lastEventTime) {
            if (this._lastEventTime !== lastEventTime) {
                this._lastEventTime = lastEventTime;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "lastEventId", {
        get: function () {
            return this._lastEventId;
        },
        set: function (lastEventId) {
            if (this._lastEventId !== lastEventId) {
                this._lastEventId = lastEventId;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BrowserConfig.prototype, "pageCounter", {
        get: function () {
            return this._pageCounter;
        },
        set: function (pageCounter) {
            if (this._pageCounter !== pageCounter) {
                this._pageCounter = pageCounter;
                this.updateStorage();
            }
        },
        enumerable: false,
        configurable: true
    });
    BrowserConfig.prototype.updateStorage = function () {
        var cache = {
            deviceId: this._deviceId,
            userId: this._userId,
            sessionId: this._sessionId,
            optOut: this._optOut,
            lastEventTime: this._lastEventTime,
            lastEventId: this._lastEventId,
            pageCounter: this._pageCounter,
        };
        void this.cookieStorage.set(getCookieName(this.apiKey), cache);
    };
    return BrowserConfig;
}(Config));
export { BrowserConfig };
export var useBrowserConfig = function (apiKey, options, amplitudeInstance) {
    if (options === void 0) { options = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var identityStorage, cookieOptions, _a, _b, cookieStorage, legacyCookies, previousCookies, queryParams, deviceId, lastEventId, lastEventTime, optOut, sessionId, userId, trackingOptions, pageCounter;
        var _c;
        var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
        return __generator(this, function (_2) {
            switch (_2.label) {
                case 0:
                    identityStorage = options.identityStorage || DEFAULT_IDENTITY_STORAGE;
                    _c = {};
                    if (!(identityStorage !== DEFAULT_IDENTITY_STORAGE)) return [3 /*break*/, 1];
                    _a = '';
                    return [3 /*break*/, 5];
                case 1:
                    if (!((_e = (_d = options.cookieOptions) === null || _d === void 0 ? void 0 : _d.domain) !== null && _e !== void 0)) return [3 /*break*/, 2];
                    _b = _e;
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getTopLevelDomain()];
                case 3:
                    _b = (_2.sent());
                    _2.label = 4;
                case 4:
                    _a = _b;
                    _2.label = 5;
                case 5:
                    cookieOptions = __assign.apply(void 0, [(_c.domain = _a, _c.expiration = 365, _c.sameSite = 'Lax', _c.secure = false, _c.upgrade = true, _c), options.cookieOptions]);
                    cookieStorage = createCookieStorage(options.identityStorage, cookieOptions);
                    return [4 /*yield*/, parseLegacyCookies(apiKey, cookieStorage, (_g = (_f = options.cookieOptions) === null || _f === void 0 ? void 0 : _f.upgrade) !== null && _g !== void 0 ? _g : true)];
                case 6:
                    legacyCookies = _2.sent();
                    return [4 /*yield*/, cookieStorage.get(getCookieName(apiKey))];
                case 7:
                    previousCookies = _2.sent();
                    queryParams = getQueryParams();
                    deviceId = (_l = (_k = (_j = (_h = options.deviceId) !== null && _h !== void 0 ? _h : queryParams.deviceId) !== null && _j !== void 0 ? _j : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.deviceId) !== null && _k !== void 0 ? _k : legacyCookies.deviceId) !== null && _l !== void 0 ? _l : UUID();
                    lastEventId = (_m = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.lastEventId) !== null && _m !== void 0 ? _m : legacyCookies.lastEventId;
                    lastEventTime = (_o = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.lastEventTime) !== null && _o !== void 0 ? _o : legacyCookies.lastEventTime;
                    optOut = (_q = (_p = options.optOut) !== null && _p !== void 0 ? _p : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.optOut) !== null && _q !== void 0 ? _q : legacyCookies.optOut;
                    sessionId = (_r = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.sessionId) !== null && _r !== void 0 ? _r : legacyCookies.sessionId;
                    userId = (_t = (_s = options.userId) !== null && _s !== void 0 ? _s : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.userId) !== null && _t !== void 0 ? _t : legacyCookies.userId;
                    amplitudeInstance.previousSessionDeviceId = (_u = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.deviceId) !== null && _u !== void 0 ? _u : legacyCookies.deviceId;
                    amplitudeInstance.previousSessionUserId = (_v = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.userId) !== null && _v !== void 0 ? _v : legacyCookies.userId;
                    trackingOptions = {
                        ipAddress: (_x = (_w = options.trackingOptions) === null || _w === void 0 ? void 0 : _w.ipAddress) !== null && _x !== void 0 ? _x : true,
                        language: (_z = (_y = options.trackingOptions) === null || _y === void 0 ? void 0 : _y.language) !== null && _z !== void 0 ? _z : true,
                        platform: (_1 = (_0 = options.trackingOptions) === null || _0 === void 0 ? void 0 : _0.platform) !== null && _1 !== void 0 ? _1 : true,
                    };
                    pageCounter = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.pageCounter;
                    return [2 /*return*/, new BrowserConfig(apiKey, options.appVersion, cookieStorage, cookieOptions, options.defaultTracking, deviceId, options.flushIntervalMillis, options.flushMaxRetries, options.flushQueueSize, identityStorage, options.ingestionMetadata, options.instanceName, lastEventId, lastEventTime, options.loggerProvider, options.logLevel, options.minIdLength, options.offline, optOut, options.partnerId, options.plan, options.serverUrl, options.serverZone, sessionId, options.sessionTimeout, options.storageProvider, trackingOptions, options.transport, options.useBatch, userId, pageCounter)];
            }
        });
    });
};
export var createCookieStorage = function (identityStorage, cookieOptions) {
    if (identityStorage === void 0) { identityStorage = DEFAULT_IDENTITY_STORAGE; }
    if (cookieOptions === void 0) { cookieOptions = {}; }
    switch (identityStorage) {
        case 'localStorage':
            return new LocalStorage();
        case 'sessionStorage':
            return new SessionStorage();
        case 'none':
            return new MemoryStorage();
        case 'cookie':
        default:
            return new CookieStorage(__assign(__assign({}, cookieOptions), { expirationDays: cookieOptions.expiration }));
    }
};
export var createTransport = function (transport) {
    if (transport === 'xhr') {
        return new XHRTransport();
    }
    if (transport === 'beacon') {
        return new SendBeaconTransport();
    }
    return new FetchTransport();
};
export var getTopLevelDomain = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var host, parts, levels, storageKey, i, i, domain, options, storage, value;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, new CookieStorage().isEnabled()];
            case 1:
                if (!(_a.sent()) || (!url && typeof location === 'undefined')) {
                    return [2 /*return*/, ''];
                }
                host = url !== null && url !== void 0 ? url : location.hostname;
                parts = host.split('.');
                levels = [];
                storageKey = 'AMP_TLDTEST';
                for (i = parts.length - 2; i >= 0; --i) {
                    levels.push(parts.slice(i).join('.'));
                }
                i = 0;
                _a.label = 2;
            case 2:
                if (!(i < levels.length)) return [3 /*break*/, 7];
                domain = levels[i];
                options = { domain: '.' + domain };
                storage = new CookieStorage(options);
                return [4 /*yield*/, storage.set(storageKey, 1)];
            case 3:
                _a.sent();
                return [4 /*yield*/, storage.get(storageKey)];
            case 4:
                value = _a.sent();
                if (!value) return [3 /*break*/, 6];
                return [4 /*yield*/, storage.remove(storageKey)];
            case 5:
                _a.sent();
                return [2 /*return*/, '.' + domain];
            case 6:
                i++;
                return [3 /*break*/, 2];
            case 7: return [2 /*return*/, ''];
        }
    });
}); };
